import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import { NavLink, withRouter } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRightOutlined';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

import { NS } from '@tafs/i18n/i18nextConfig';
import { sideMenuConfig } from '@tafs/routeConfig';
import styles from './index.module.css';
import ShadowScrollbars from '@tafs/components/common/ShadowScrollbars';
import { saveSideMenu } from '@tafs/acs/sideMenu';
import { isPartOf } from '@tafs/constants/permissions';
import { getWorkspacePath } from '@tafs/constants/workspaces';
import { withTranslation } from 'react-i18next';
import WorkspaceSelect from './WorkspaceSelect';

const { LITE_MODE } = process.env;

class SideMenu extends React.Component {
  state = {
    expanded: true,
    closedMenuHoveredItem: undefined,
  };

  componentDidMount() {
    this.expandGroupByPath();
  }

  expandGroupByPath = () => {
    const {
      groupsOpened,
      saveSideMenu,
      location: { pathname },
    } = this.props;

    let groupMenuItem;
    sideMenuConfig.forEach((el) => {
      const relatedChild =
        el.children &&
        el.children.find(
          (child) => child.path && pathname.includes(child.path)
        );

      if (relatedChild) groupMenuItem = el;
    });

    if (groupMenuItem && !groupsOpened[groupMenuItem.name]) {
      saveSideMenu({
        ...groupsOpened,
        [groupMenuItem.name]: !groupsOpened[groupMenuItem.name],
      });
    }
  };

  handleMouseEnter = (item) => () =>
    this.setState({ closedMenuHoveredItem: item });

  handleMouseLeave = () => this.setState({ closedMenuHoveredItem: null });

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  handleToggleGroup = (groupName) => {
    const { groupsOpened, saveSideMenu } = this.props;

    saveSideMenu({
      ...groupsOpened,
      [groupName]: !groupsOpened[groupName],
    });
  };

  subrouteSelected = (children) => {
    const {
      location: { pathname },
    } = this.props;

    return children.some(
      (child) => child.path && pathname.includes(child.path)
    );
  };

  getItemContent = (itemConf, nested) => {
    const { expanded, closedMenuHoveredItem } = this.state;
    const { t } = this.props;

    if (expanded || nested)
      return (
        <ListItemText
          primaryTypographyProps={{ className: styles.text }}
          className={styles.text}
          primary={t(itemConf.name)}
        />
      );

    return (
      closedMenuHoveredItem === itemConf.name && (
        <div className={styles.hoveredContent}>
          <ListItemText
            primaryTypographyProps={{ className: styles.text }}
            className={styles.text}
            primary={t(itemConf.name)}
            inset={!!itemConf.icon}
          />
        </div>
      )
    );
  };

  createItem = (itemConf, parent) => {
    const { expanded } = this.state;
    const { permissions, workspace } = this.props;
    const itemWorkspace = LITE_MODE ? itemConf.workspace : workspace;

    const itemPermissions = itemConf.allowed.map(
      (permissionSuffix) => `${itemWorkspace.permission}.${permissionSuffix}`
    );

    return (
      itemConf.workspaces.map((ws) => ws.id).includes(itemWorkspace.id) &&
      itemPermissions.some((itemPermission) =>
        isPartOf(itemPermission, permissions)
      ) && (
        <div
          key={`${itemConf.workspace?.id || itemConf.path}_${itemConf.path}_${
            itemConf.name
          }_${parent && parent.id}`}
        >
          {!itemConf.children ? (
            itemConf.disabled ? (
              <ListItem
                disabled
                button
                className={classNames({ [styles.nestedItem]: !!parent }, [
                  styles.item,
                ])}
              >
                <ListItemIcon className={styles.icon}>
                  {itemConf.icon}
                </ListItemIcon>
                {this.getItemContent(itemConf, parent)}
              </ListItem>
            ) : (
              <NavLink
                to={getWorkspacePath(itemWorkspace, itemConf.path)}
                activeClassName={styles.activeItem}
                className={styles.item}
              >
                <ListItem
                  onMouseEnter={
                    !expanded ? this.handleMouseEnter(itemConf.name) : undefined
                  }
                  onMouseLeave={!expanded ? this.handleMouseLeave : undefined}
                  button
                  className={classNames({ [styles.nestedItem]: !!parent }, [
                    styles.item,
                  ])}
                >
                  <ListItemIcon className={styles.icon}>
                    {itemConf.icon}
                  </ListItemIcon>
                  {this.getItemContent(itemConf, parent)}
                </ListItem>
              </NavLink>
            )
          ) : (
            this.createNestedItem(itemConf)
          )}
        </div>
      )
    );
  };

  createNestedItem = (itemConf) => {
    const { expanded } = this.state;
    const { groupsOpened, t } = this.props;

    return (
      <>
        <ListItem
          key={`nested_${itemConf.name}`}
          onMouseEnter={
            !expanded ? this.handleMouseEnter(itemConf.name) : undefined
          }
          onMouseLeave={!expanded ? this.handleMouseLeave : undefined}
          button
          className={classNames(styles.item, {
            [styles.itemGroupCollapsed]: !expanded,
            [styles.activeGroup]:
              this.subrouteSelected(itemConf.children) &&
              (!expanded || !groupsOpened[itemConf.name]),
          })}
          disabled={itemConf.disabled}
          onClick={() => this.handleToggleGroup(itemConf.name)}
        >
          <ListItemIcon className={styles.icon}>{itemConf.icon}</ListItemIcon>
          <ListItemText
            className={styles.text}
            primaryTypographyProps={{ className: styles.text }}
            primary={t(itemConf.name)}
          />
          {expanded &&
            (groupsOpened[itemConf.name] ? <ExpandLess /> : <ExpandMore />)}
          {
            <List
              component="div"
              disablePadding
              className={classNames(styles.nestedGroup, styles.hoveredGroup)}
            >
              {itemConf.children.map((item) => this.createItem(item, true))}
            </List>
          }
        </ListItem>
        {expanded && (
          <Collapse
            in={groupsOpened[itemConf.name] && expanded}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {itemConf.children.map((item) => this.createItem(item, true))}
            </List>
          </Collapse>
        )}
      </>
    );
  };

  render() {
    const { className, scrollPosition } = this.props;
    const { expanded } = this.state;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            styles.menu,
            expanded && styles.expanded,
            className
          ),
        }}
      >
        <div>
          <Button
            className={styles.expandButton}
            fullWidth
            onClick={this.toggleExpanded}
          >
            {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Button>
        </div>
        <Divider />
        {!LITE_MODE && <WorkspaceSelect expanded={expanded} />}
        <ShadowScrollbars
          className={styles.sideMenu}
          scrollPosition={scrollPosition}
        >
          <List component="nav">
            {sideMenuConfig.map((item) => this.createItem(item, false))}
          </List>
        </ShadowScrollbars>
      </Drawer>
    );
  }
}

SideMenu.propTypes = {
  open: PropTypes.bool,
  location: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = {
  saveSideMenu,
};

const mapStateToProps = function (state) {
  return {
    groupsOpened: state.sideMenu.groupsOpened || {},
    scrollPosition: state.sideMenu.scrollPosition,
    permissions: state.user.permissions,
    workspace: state.app.workspace,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation(NS.NAVIGATION)(SideMenu))
);
