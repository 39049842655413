import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CogIcon from '@material-ui/icons/SettingsOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import SettingsDialog from './SettingsDialog';

const { LITE_MODE } = process.env;

const AppSettings = () => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const visible = useSelector((state) => {
    const { run_settings, schedules_settings, deletion } =
      state.user.permissions?.analytic_ib?.schedule || {};
    return (
      (LITE_MODE && run_settings?.view) ||
      (LITE_MODE && schedules_settings?.view) ||
      deletion?.view
    );
  });
  const { t } = useTranslation();

  if (!visible) return <></>;

  return (
    <>
      <Tooltip title={t('Settings')} enterDelay={300}>
        <IconButton color="inherit" onClick={() => setSettingsDialogOpen(true)}>
          <CogIcon />
        </IconButton>
      </Tooltip>
      <SettingsDialog
        open={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
      />
    </>
  );
};

export default AppSettings;
