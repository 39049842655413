import {
  signalMoex,
  signalMm,
  signalManual,
} from '@tafs/constants/signals/signalTypes';

const CARD = '/card/:id';

/**********************************************************************************/

export const LOGIN_PAGE = '/login';

/**********************************************************************************/

export const SCAN_PAGE = '/scan';

/**********************************************************************************/

export const SCHEDULED_PAGE = '/schedule';
export const SCHEDULED_REPORTS_PAGE = SCHEDULED_PAGE + '/reportpage';
export const SCHEDULED_RULES_PAGE = SCHEDULED_PAGE + '/rules';
export const SCHEDULED_INCIDENT_LIST_PAGE = SCHEDULED_PAGE + '/incidents';
export const SCHEDULED_INCIDENT_PAGE =
  SCHEDULED_INCIDENT_LIST_PAGE + '/incident/:id';

export const SCHEDULED_MARKET_CHART_PAGE =
  SCHEDULED_REPORTS_PAGE + '/market-chart';
export const SCHEDULED_ASSOCIATED_DEALS_PAGE =
  SCHEDULED_INCIDENT_LIST_PAGE + '/associated-deals/:id';
export const SCHEDULED_NEIGHBORHOOD_DEALS_PAGE =
  SCHEDULED_INCIDENT_LIST_PAGE + '/neighborhood-deals/:id';

export const SCHEDULED_BUSINESS_ADMIN_PANEL_PAGE =
  SCHEDULED_PAGE + '/analyst-panel';

/**********************************************************************************/

export const INVESTIGATION_PAGE = '/investigation';
export const INVESTIGATION_REPORTS_PAGE = INVESTIGATION_PAGE + '/reportpage';
export const INVESTIGATION_RULES_PAGE = INVESTIGATION_PAGE + '/rules';
export const INVESTIGATION_INCIDENT_LIST_PAGE =
  INVESTIGATION_PAGE + '/incidents';
export const INVESTIGATION_INCIDENT_PAGE =
  INVESTIGATION_INCIDENT_LIST_PAGE + '/incident/:id';

export const INVESTIGATION_MARKET_CHART_PAGE =
  INVESTIGATION_REPORTS_PAGE + '/market-chart';
export const INVESTIGATION_ASSOCIATED_DEALS_PAGE =
  INVESTIGATION_INCIDENT_LIST_PAGE + '/associated-deals/:id';
export const INVESTIGATION_NEIGHBORHOOD_DEALS_PAGE =
  INVESTIGATION_INCIDENT_LIST_PAGE + '/neighborhood-deals/:id';

export const INVESTIGATION_CUSTOM_RULES = INVESTIGATION_PAGE + '/custom-rules';
export const INVESTIGATION_RULE_CONSTRUCTOR =
  INVESTIGATION_CUSTOM_RULES + '/rule-constructor';

/**********************************************************************************/

export const SANDBOX_PAGE = '/sandbox';
export const SANDBOX_REPORTS_PAGE = SANDBOX_PAGE + '/reportpage';
export const SANDBOX_RULES_PAGE = SANDBOX_PAGE + '/rules';
export const SANDBOX_INCIDENT_LIST_PAGE = SANDBOX_PAGE + '/incidents';
export const SANDBOX_INCIDENT_PAGE =
  SANDBOX_INCIDENT_LIST_PAGE + '/incident/:id';

/**********************************************************************************/

export const UPLOAD_PAGE = '/upload-data';

/**********************************************************************************/

export const INITIAL_DATA_PAGE = '/initial-data';
export const DEALS_MOEX_PAGE = INITIAL_DATA_PAGE + '/deals-moex';
export const ORDERS_MOEX_PAGE = INITIAL_DATA_PAGE + '/orders-moex';

/**********************************************************************************/

export const CASES_PAGE = '/cases';
export const CASE_CARD = CASES_PAGE + CARD;

/**********************************************************************************/

export const WATCH_LISTS_PAGE = '/watchlists';
export const WATCH_LIST_CARD_PAGE = WATCH_LISTS_PAGE + CARD;
export const WATCH_LISTS_INCIDENT_LIST_PAGE = WATCH_LISTS_PAGE + '/incidents';

/**********************************************************************************/

export const REFERENCE_PAGE = '/reference';
export const REFERENCE_ISSUES_PAGE = REFERENCE_PAGE + '/issues';
export const REFERENCE_INSIDERS_PAGE = REFERENCE_PAGE + '/insiders';
export const REFERENCE_INSIDERS_CARD_PAGE = REFERENCE_INSIDERS_PAGE + CARD;
export const REFERENCE_STAFF_PAGE = REFERENCE_PAGE + '/staff';
export const REFERENCE_STAFF_CARD_PAGE = REFERENCE_STAFF_PAGE + CARD;
export const REFERENCE_COUNTERPARTY_PAGE = REFERENCE_PAGE + '/counterparty';
export const REFERENCE_COUNTERPARTY_CARD_PAGE =
  REFERENCE_COUNTERPARTY_PAGE + CARD;
export const REFERENCE_WORKING_DAY_PAGE = REFERENCE_PAGE + '/working-day';
export const REFERENCE_RATES_PAGE = REFERENCE_PAGE + '/rates';
export const REFERENCE_POSITIONS_PAGE = REFERENCE_PAGE + '/positions';
export const REFERENCE_TERMS_PAGE = REFERENCE_PAGE + '/definitions';

export const DEALS_PAGE = REFERENCE_PAGE + '/deals';
export const RAW_DATA_DEALS_PAGE = DEALS_PAGE + '/raw-data';
export const DEAL_CARD_PAGE = DEALS_PAGE + CARD;
export const ORDERS_PAGE = REFERENCE_PAGE + '/orders';
export const RAW_DATA_ORDERS_PAGE = ORDERS_PAGE + '/raw-data';
export const ORDER_CARD_PAGE = ORDERS_PAGE + CARD;
export const MARKET_PAGE = REFERENCE_PAGE + '/market';
export const MARKET_DAYS_PAGE = REFERENCE_PAGE + '/market-days';
export const NEWS_PAGE = REFERENCE_PAGE + '/news';
export const NEWS_CARD_PAGE = NEWS_PAGE + CARD;
export const INDEXES_PAGE = REFERENCE_PAGE + '/indexes';
export const FIXING_INTEREST_PAGE = REFERENCE_PAGE + '/fixing-interest';
export const MOEX_FX_PAGE = REFERENCE_PAGE + '/moex-fx';
export const CROSS_DEALS_PAGE = REFERENCE_PAGE + '/cross-deals';
export const CROSS_DEAL_CARD_PAGE = CROSS_DEALS_PAGE + CARD;

/**********************************************************************************/

export const ADMIN_PAGE = '/admin';
export const ADMIN_USER_MANAGEMENT_PAGE = ADMIN_PAGE + '/user-management';
export const ADMIN_APPLICATION_MONITORING =
  ADMIN_PAGE + '/application-monitoring';

export const SECURITY_ADMIN_PAGE = '/security-admin';
export const SECURITY_ADMIN_REF_AUDIT_PAGE = SECURITY_ADMIN_PAGE + '/ref-audit';
export const SECURITY_ADMIN_USER_AUDIT_PAGE =
  SECURITY_ADMIN_PAGE + '/user-audit';
export const SECURITY_ADMIN_USER_ACTION_STATS_PAGE =
  SECURITY_ADMIN_PAGE + '/user-action-stats';

/**********************************************************************************/

export const DEV_TOOLS_PAGE = '/dev-tools';

/**********************************************************************************/

export const SIGNALS_PAGE = '/signals';
export const SIGNALS_LIST_PAGE_MOEX = SIGNALS_PAGE + '/' + signalMoex;
export const SIGNALS_LIST_PAGE_MM = SIGNALS_PAGE + '/' + signalMm;
export const SIGNAL_PAGE_MOEX = SIGNALS_LIST_PAGE_MOEX + '/signal/:id';
export const SIGNAL_PAGE_MM = SIGNALS_LIST_PAGE_MM + '/signal/:id';
export const SIGNALS_RULES_PAGE = SIGNALS_PAGE + '/rules';
export const SIGNALS_LIST_PAGE_MANUAL = SIGNALS_RULES_PAGE + '/' + signalManual;
export const SIGNAL_PAGE_MANUAL = SIGNALS_LIST_PAGE_MANUAL + '/signal/:id';

/**********************************************************************************/

export const REPORTS_PAGE = '/reports';
export const REPORTS_PAGE_ML = REPORTS_PAGE + '/ml-pivot';

export const OVERLAP_INCIDENTS_PAGE = REPORTS_PAGE + '/overlap-incidents';
export const OVERLAP_DEALS_ORDERS_PAGE = REPORTS_PAGE + '/overlap-deals-orders';
export const OVERLAP_COUNTERPARTY_PAGE = REPORTS_PAGE + '/overlap-counterparty';
export const UNPROCESSED_INCIDENTS_PAGE =
  REPORTS_PAGE + '/unprocessed-incidents';

/**********************************************************************************/

export const AUDIT_CLICK_PAGE = '/one-click-audit';

/**********************************************************************************/

export const TAFS_REPORTS_PAGE = '/tafs-reports';

/**********************************************************************************/

export const MOEX_REQUESTS_PAGE = '/moex-requests';

/**********************************************************************************/

export const RISK_MODEL_PAGE = '/risk-model';
