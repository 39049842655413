import React from 'react';
import i18next from 'i18next';

import AgSelect from './Select';
import styles from './index.module.css';
import { NS } from '@tafs/i18n/i18nextConfig';
import { formatNumber } from '@tafs/utils';
import { effectivenessRuleGroup } from '@tafs/constants/reports/ruleGroups';
import TruncatableCell, { MAX_CELL_WIDTH } from './TruncatableCell';

const { GPB_MODE } = process.env;

const isEnumValueDisplayed = (value) =>
  GPB_MODE || value !== effectivenessRuleGroup.id;

const getEnum = (entitySchema, type, cellRendererFramework) => {
  const dataType = entitySchema.find((d) => d.name === type);
  if (dataType)
    return {
      cellRendererFramework,
      filterParams: {
        applyButton: true,
        resetButton: true,
        newRowsAction: 'keep',
        values: dataType.enumValues
          .map((value) => value.name)
          .filter(isEnumValueDisplayed),
      },
      filter: 'agSetColumnFilter',
    };
};

const getTranslatedEnum = (prefix, ...rest) => {
  const enm = getEnum(...rest);
  if (enm) {
    prefix = prefix ? prefix + '.' : '';
    enm.valueFormatter = (params) => {
      return i18next.t(`${prefix}${params.value}`);
    };
    return enm;
  }
};
const getTypedColDef = ({
  type,
  cellRendererFramework,
  valueFormatter,
  cellEditor,
  editable,
  nullable,
  entitySchema,
  valueGetter,
  truncatable,
}) => {
  let res;
  switch (type) {
    case 'UUID':
      return {
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          defaultOption: 'equals',
          filterOptions: ['equals'],
        },
        filter: 'agTextColumnFilter',
        cellRendererFramework,
      };
    case 'FeedingMethodOrderEnum':
    case 'AssetClassEnum':
    case 'ContractDirectionEnum':
    case 'DealmodeEnum':
    case 'DealtypeEnum':
    case 'InvestorTypeEnum':
    case 'SignalAlgoSourceEnum':
    case 'SignalMarketMakerReportTypeEnum':
    case 'StatusEnum':
      res = getEnum(entitySchema, type, cellRendererFramework);
      if (res) return res;
    /* eslint-disable-next-line no-fallthrough */
    case 'CaseTypeEnum':
      res = getTranslatedEnum(
        'caseType',
        entitySchema,
        type,
        cellRendererFramework
      );
      if (res) return res;
    /* eslint-disable-next-line no-fallthrough */
    case 'CaseStatusEnum':
      res = getTranslatedEnum(
        'caseStatus',
        entitySchema,
        type,
        cellRendererFramework
      );
      if (res) return res;
    /* eslint-disable-next-line no-fallthrough */
    case 'ModeEnum':
      res = getTranslatedEnum(
        'mode',
        entitySchema,
        type,
        cellRendererFramework
      );
      if (res) return res;
    /* eslint-disable-next-line no-fallthrough */
    case 'RuleGroupEnum':
    case 'IncidentManagementStatusEnum':
    case 'StatusReportEnum':
      res = getTranslatedEnum('', entitySchema, type, cellRendererFramework);
      if (res) return res;
    /* eslint-disable-next-line no-fallthrough */
    case 'String':
      return {
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          defaultOption: 'contains',
          filterOptions: [
            'equals',
            'notEqual',
            'startsWith',
            'endsWith',
            'contains',
            'notContains',
          ],
        },
        filter: 'agTextColumnFilter',
        cellRendererFramework,
        valueGetter,
        ...(truncatable && {
          cellRendererFramework: TruncatableCell,
          maxWidth: MAX_CELL_WIDTH,
        }),
      };
    case 'Boolean':
      return {
        editable: false,
        cellRendererFramework:
          cellRendererFramework || AgSelect(editable, nullable),
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          values: ['true', 'false'],
        },
        filter: 'agSetColumnFilter',
      };
    case 'BigDecimal':
    case 'Long':
    case 'Int':
      const getValue = (props) => formatNumber(props.value, 8);
      return {
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          defaultOption: 'greaterThan',
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'greaterThan',
            {
              displayKey: 'ISNULL',
              displayName: 'ISNULL',
              test: (_, cellValue) => cellValue == null,
              hideFilterInput: true,
            },
            {
              displayKey: 'ISNOTNULL',
              displayName: 'ISNOTNULL',
              test: (_, cellValue) => cellValue != null,
              hideFilterInput: true,
            },
          ],
        },
        filter: 'agNumberColumnFilter',
        valueFormatter: getValue,
        cellRendererFramework:
          cellRendererFramework ||
          ((props) => (
            <div className={styles.numberCell}>{getValue(props)}</div>
          )),
      };
    case 'SqlDate':
    case 'LocalDate':
    case 'Date':
      return {
        cellEditor: cellEditor || 'datePicker',
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          defaultOption: 'equals',
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan'],
        },
        filter: 'agDateColumnFilter',
      };
    case 'LocalDateTime':
    case 'Timestamp':
      return {
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          defaultOption: 'greaterThan',
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan'],
        },
        filter: 'agDateColumnFilter',
        isTimestamp: true,
      };
    // TODO: Add input template for time datatype when AgGrid is updated past v22
    // (or design custom filter component if RnD would desire the feature ASAP)
    case 'LocalTime':
      return {
        filterParams: {
          applyButton: true,
          resetButton: true,
          newRowsAction: 'keep',
          defaultOption: 'greaterThan',
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan'],
        },
        filter: 'agTextColumnFilter',
      };
    default:
      console.error(`Unknown column type ${type}`);
      return {
        filter: false,
        cellRendererFramework,
        cellEditor,
      };
  }
};

const getEnumEditorSelector = (isEditable, typeName, entitySchema) => {
  if (typeName.includes('Enum') && isEditable) {
    const dataType = entitySchema.find((d) => d.name === typeName);

    if (dataType)
      return () => ({
        component: 'agRichSelect',
        params: {
          values: dataType.enumValues.map((value) => value.name),
        },
      });
  }

  return null;
};

export const constructColumnsDefs = (
  columns,
  entityType,
  entitySchema,
  hasMasterRow
) => {
  const entity = trimEntityName(entityType);
  const type = entitySchema.find((type) => type.name === entity);
  const getCompoundName = (field) => {
    const nameArr = field.split('.');

    if (nameArr.length === 1) return `${entity}.${field}`;

    // joined graphql field searches schema for corresponding entity translation
    const res = nameArr.reduce(
      (acc, cur, idx) => {
        if (idx === nameArr.length - 1) return `${acc.entity}.${cur}`;
        const joinedType = acc.schema.fields.find((f) => f.name === cur);
        const joinedEntity =
          joinedType.type?.name || joinedType.type?.ofType?.name;
        const joinedSchema =
          entitySchema &&
          entitySchema.find((type) => type.name === joinedEntity);
        return { schema: joinedSchema, entity: joinedEntity || cur };
      },
      { schema: type }
    );
    return res;
  };

  const columnFn = (column) => {
    const headerName = column.headerName
      ? i18next.t(column.headerName)
      : i18next.t(getCompoundName(column.field), {
          ns: NS.ENTITIES,
        });

    if (!column.field) {
      const obj = {
        headerName,
        field: '',
        hide: column.hide,
        cellRendererFramework: column.cellRendererFramework,
        filter: column.filter,
        sortable: column.sortable !== undefined ? column.sortable : undefined,
        editable: column.editable ? true : false,
        cellEditorSelector: column.cellEditorSelector,
        cellEditorParams: column.cellEditorParams,
        cellEditor: column.cellEditor,
        sort: column.sort,
      };
      if (column.children?.length) {
        obj.children = column.children?.map((ch) => columnFn(ch));
      }
      return obj;
    } else {
      const paths = column.field.split('.');
      // recursive search field type in gq schema
      const fieldSchema = paths.reduce((schema, path) => {
        let gqType =
          schema &&
          schema.fields &&
          schema.fields.find((field) => field.name === path);

        if (!gqType) {
          console.info(`🤔 Field ${path} for entity ${entityType} is not found.
            It maybe filtered by rights system or was influenced by the Li 🧙‍♂️ curse and field name should be verified`);
          return null;
        }

        if (gqType.type.kind === 'OBJECT') {
          gqType = entitySchema.find((type) => type.name === gqType.type.name);
        } else if (gqType.type.kind === 'LIST') {
          gqType = entitySchema.find(
            (type) => type.name === gqType.type.ofType.name
          );
        }
        return gqType.fields
          ? gqType
          : gqType.type.fields
          ? gqType.type
          : gqType;
      }, type);
      // field exist in schema, that means user have rights to see it
      if (fieldSchema) {
        return {
          headerName,
          field: column.field,
          hide: column.hide,
          cellEditor: column.cellEditor,
          editable: column.editable,
          ...getTypedColDef({
            type: fieldSchema.type.name,
            cellRendererFramework: column.cellRendererFramework,
            editable: column.editable,
            nullable: column.nullable,
            cellEditor: column.cellEditor,
            valueFormatter: column.valueFormatter,
            valueGetter: column.valueGetter,
            entitySchema,
            truncatable: column.truncatable,
          }),
          ...(column.filter !== undefined && { filter: column.filter }),
          sortable: column.sortable !== undefined ? column.sortable : true,
          cellEditorSelector:
            column.cellEditorSelector ||
            getEnumEditorSelector(
              column.editable,
              fieldSchema.type.name,
              entitySchema
            ),
          cellEditorParams: column.cellEditorParams,
          sort: column.sort,
          checkboxSelection: column.checkboxSelection,
          headerComponent: column.headerComponent,
          pinned: column.pinned,
          cellStyle: column.cellStyle,
        };
      }
    }
  };

  const defs = columns
    .filter((column) => !column.ignore)
    .map((column) => {
      return columnFn(column);
    });

  if (hasMasterRow)
    defs.unshift({
      field: '',
      valueGetter: () => '',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      maxWidth: 30,
    });

  return defs;
};

function flattenArray(arr) {
  const result = [];

  for (const item of arr) {
    if (item.children) {
      result.push(...item.children);
    } else {
      result.push(item);
    }
  }

  return result;
}

export const getQueryFields = (columns, entityType, entitySchema) => {
  const defs = [];
  const entity = trimEntityName(entityType);
  const type = entitySchema.find((type) => type.name === entity);
  const columnsWithChildren = flattenArray(columns);
  columnsWithChildren
    .filter((col) => col.field)
    .forEach((column) => {
      const paths = column.field.split('.');
      // recursive search field type in gq schema
      const fieldSchema = paths.reduce((schema, path) => {
        let gqType =
          schema &&
          schema.fields &&
          schema.fields.find((field) => field.name === path);
        if (!gqType) {
          console.info(`🤔 Field ${path} for entity ${entityType} is not found.
          It maybe filtered by rights system or was influenced by the Li 🧙‍♂️ curse and field name should be verified`);
          return null;
        }
        if (gqType.type.kind === 'OBJECT') {
          gqType = entitySchema.find((type) => type.name === gqType.type.name);
        } else if (gqType.type.kind === 'LIST') {
          gqType = entitySchema.find(
            (type) => type.name === gqType.type.ofType.name
          );
        }
        return gqType.fields
          ? gqType
          : gqType.type.fields
          ? gqType.type
          : gqType;
      }, type);
      if (!fieldSchema) {
        console.info(
          `🤔 Schema for field ${column.field} of entity ${entityType} is not found.`
        );
        return;
      }
      // field exist in schema, that means user have rights to see it
      if (fieldSchema) {
        defs.push({
          field: column.field,
        });
      }
    });
  return defs;
};

export const getQueryString = (fields) => {
  return (
    JSON.stringify(fields)
      // remove '[' from start and ']' from end
      .replace(new RegExp('^\\[|]$', 'g'), '')
      // temporery replace '":"' and ':[' with '#ob' to later replace '#ob' with '{'
      .replace(new RegExp('":"|:\\[', 'g'), '#ob')
      // remove '{' from start and '}' from end
      .replace(new RegExp('^{|}$', 'g'), '')
      // remove all '{' , '}', '"' chars
      .replace(new RegExp('[{}"]', 'g'), ' ')
      // replace ',' with space for readability
      .replace(new RegExp(',', 'g'), '\n')
      // replace '[' with '}'
      .replace(new RegExp(']', 'g'), '}')
      // replace '#ob' with '{'
      .replace(new RegExp('#ob', 'g'), '{\n')
  );
};

export const trimEntityName = (entity) =>
  entity.endsWith('List') ? entity.slice(0, -4) : entity;

export const addLikeEscapeChars = (str) =>
  str.replaceAll('_', '\\_').replaceAll('%', '\\%');
